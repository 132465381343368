const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authGuest'] = require('../middleware/authGuest.js')
middleware['authGuest'] = middleware['authGuest'].default || middleware['authGuest']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['loadCurrentUser'] = require('../middleware/loadCurrentUser.js')
middleware['loadCurrentUser'] = middleware['loadCurrentUser'].default || middleware['loadCurrentUser']

middleware['loadOrganizations'] = require('../middleware/loadOrganizations.js')
middleware['loadOrganizations'] = middleware['loadOrganizations'].default || middleware['loadOrganizations']

middleware['onlyQA'] = require('../middleware/onlyQA.js')
middleware['onlyQA'] = middleware['onlyQA'].default || middleware['onlyQA']

middleware['organizationInvite'] = require('../middleware/organizationInvite.js')
middleware['organizationInvite'] = middleware['organizationInvite'].default || middleware['organizationInvite']

middleware['processShortcode'] = require('../middleware/processShortcode.js')
middleware['processShortcode'] = middleware['processShortcode'].default || middleware['processShortcode']

export default middleware
