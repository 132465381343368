<template lang="pug">
  v-btn(v-if="false" class="main-button w-100 mb-6" @click="openUploadDialog") {{ $t('Create') }}
  e-button-upload-file(
    v-else
    ref="uploader"
    :validation-rules="rules"
    :accept="acceptedFileExtensions"
    multiple
    button-class="w-100 mb-6"
    :loading="loading"
    @onload="upload"
  )
    span {{ $t('Create') }}
</template>

<script>
import Base64js from 'base64-js'
import contentDialog from '~/mixins/dialogs/contentDialog'
import converters from '~/mixins/methods/converters'
import Template from '~/modules/templates/models/Template'
import EButtonUploadFile from '~/components/elements/buttons/e-button-upload-file'
import uploadValidation from '~/modules/templates/mixins/uploadValidation'

export default {
  name: 'EButtonTemplateUpload',
  components: {
    EButtonUploadFile
  },
  mixins: [uploadValidation, converters, contentDialog],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async upload (files) {
      const { valid, errors } = await this.$refs.uploader.validate(files)
      if (!valid) {
        const errMessage = this._.get(errors, '[0]')
        this.$notification.error(errMessage)
        return
      }
      try {
        this.loading = true
        for (const file of files) {
          const arrayBuffer = await this.blobToArrayBuffer(file)
          const uint8View = new Uint8Array(arrayBuffer)
          const base64Str = Base64js.fromByteArray(uint8View)
          try {
            const payload = {
              fileContent: base64Str,
              filename: file?.name,
              title: file?.name.split('.').slice(0, -1).join('.')
            }
            const templateId = this._.get(await Template.api().create(payload), 'response.data.id')
            if (files && files.length === 1 && templateId) {
              await this.$router.push({ path: `/templates/${templateId}`, query: { status: 'new' } })
              return
            }
            await Template.api().read(templateId)
            this.$notification.success(this.$t('{document} has been successfully uploaded', { document: file.name }))
          } catch (e) {
            const message = this._.get(e, 'response.data.message')
            this.$notification.error(this.$t('Error occurred with {document} - {error}', {
              document: file.name,
              error: message
            }))
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    openUploadDialog () {
      this.contentDialog.open({
        component: 'm-template-upload',
        width: '900px'
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
