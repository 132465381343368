import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aea7636a = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _2161b8d6 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _e9bed37e = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _5d17a56c = () => interopDefault(import('../pages/my-organizations/index.vue' /* webpackChunkName: "pages/my-organizations/index" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _8d97ccfc = () => interopDefault(import('../pages/templates/index.vue' /* webpackChunkName: "pages/templates/index" */))
const _84c92402 = () => interopDefault(import('../pages/test/index.vue' /* webpackChunkName: "pages/test/index" */))
const _73033c71 = () => interopDefault(import('../pages/documents/archive.vue' /* webpackChunkName: "pages/documents/archive" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _10b644d5 = () => interopDefault(import('../pages/bulk-signature/_id/index.vue' /* webpackChunkName: "pages/bulk-signature/_id/index" */))
const _915aaea8 = () => interopDefault(import('../pages/documents/_id/index.vue' /* webpackChunkName: "pages/documents/_id/index" */))
const _46e812d4 = () => interopDefault(import('../pages/my-organizations/_id.vue' /* webpackChunkName: "pages/my-organizations/_id" */))
const _5e1bf6e6 = () => interopDefault(import('../pages/my-organizations/_id/users.vue' /* webpackChunkName: "pages/my-organizations/_id/users" */))
const _2c53f122 = () => interopDefault(import('../pages/open/_id/index.vue' /* webpackChunkName: "pages/open/_id/index" */))
const _9c941ed6 = () => interopDefault(import('../pages/show/_id/index.vue' /* webpackChunkName: "pages/show/_id/index" */))
const _64b3ae9e = () => interopDefault(import('../pages/tags/_id/index.vue' /* webpackChunkName: "pages/tags/_id/index" */))
const _44feb926 = () => interopDefault(import('../pages/templates/_id/index.vue' /* webpackChunkName: "pages/templates/_id/index" */))
const _abde09c8 = () => interopDefault(import('../pages/widget/_id/index.vue' /* webpackChunkName: "pages/widget/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _aea7636a,
    name: "about-us"
  }, {
    path: "/auth",
    component: _2161b8d6,
    name: "auth"
  }, {
    path: "/documents",
    component: _e9bed37e,
    name: "documents"
  }, {
    path: "/my-organizations",
    component: _5d17a56c,
    name: "my-organizations"
  }, {
    path: "/profile",
    component: _4bb7511c,
    name: "profile"
  }, {
    path: "/templates",
    component: _8d97ccfc,
    name: "templates"
  }, {
    path: "/test",
    component: _84c92402,
    name: "test"
  }, {
    path: "/documents/archive",
    component: _73033c71,
    name: "documents-archive"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/bulk-signature/:id",
    component: _10b644d5,
    name: "bulk-signature-id"
  }, {
    path: "/documents/:id",
    component: _915aaea8,
    name: "documents-id"
  }, {
    path: "/my-organizations/:id?",
    component: _46e812d4,
    name: "my-organizations-id",
    children: [{
      path: "users",
      component: _5e1bf6e6,
      name: "my-organizations-id-users"
    }]
  }, {
    path: "/open/:id",
    component: _2c53f122,
    name: "open-id"
  }, {
    path: "/show/:id",
    component: _9c941ed6,
    name: "show-id"
  }, {
    path: "/tags/:id",
    component: _64b3ae9e,
    name: "tags-id"
  }, {
    path: "/templates/:id",
    component: _44feb926,
    name: "templates-id"
  }, {
    path: "/widget/:id",
    component: _abde09c8,
    name: "widget-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
