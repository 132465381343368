export default {
  Archive: 'Архів',
  Administrator: 'Адміністратор',
  administrator: 'Адміністратор',
  Accountant: 'Бухгалтер',
  accountant: 'Бухгалтер',
  All: 'Всі',
  Add: 'Додати',
  'All documents': 'Всі документи',
  'Account settings': 'Налаштування профілю',
  Basket: 'Кошик',
  'Invite a user': 'Запросити користувача',
  'Document name': 'Назва документа',
  'Document name required': 'Назва документа*',
  'Document type': 'Тип документа',
  'Select your organization': 'Оберіть свою організацію',
  'Select your organization required': 'Оберіть свою організацію*',
  'Document total': 'Сума документа',
  Continue: 'Продовжити',
  Change: 'Змінити',
  Sign: 'Підписати',
  'To sign': 'Підписувати',
  Signature: 'Підпис',
  Send: 'Надіслати',
  Reject: 'Відхилити',
  'Sign and send': 'Підписати та надіслати',
  'Sign type': 'Тип підписання',
  Date: 'Дата',
  'Date required': 'Дата*',
  'Sign order': 'Порядок підписання',
  'Document number': 'Номер документа',
  'Document number required': 'Номер документа*',
  'Download document': 'Завантажити документ',
  'Download documents': 'Завантажити документи',
  Counterpart: 'Контрагент',
  Counterparts: 'Контрагенти',
  'Email required': 'Email*',
  'Edrpou required': 'ЄДРПОУ*',
  Types: 'Типи',
  Statuses: 'Всі статуси',
  types: 'типи',
  counterparts: 'контрагенти',
  statuses: 'статуси',
  Edrpou: 'ЄДРПОУ',
  'Click to download': 'Натисніть, щоб завантажити',
  'or drag and drop the file in the format:': 'або перетягніть файл в форматі:',
  Downloaded: 'Завантажено',
  Download: 'Завантажити',
  Document: 'Документ',
  Documents: 'Документи',
  'Required fields': '*Поля обовʼязкові',
  'File format is not allowed to download': 'Формат файлу не є доступним для завантаження',
  'Select eds key': 'Оберіть ключ ЕЦП',
  'Organization exists': 'Організація з цим ЄДРПОУ вже зареєстрована',
  'Organization successfully added': 'Організацію успішно додано',
  'There are no documents': 'Документів немає',
  'There are no organizations': 'Організацій немає',
  'There are no users': 'Користувачів немає',
  'View your current membership roles and the list of organizations you manage': 'Перегляньте свої поточні ролі членства і список організацій, якими ви керуєте. Ви можете приєднатися тільки до однієї організації як член, це застосує до вас усі налаштування організації. Тим паче, ви можете керувати кількома організаціями як модератор або адміністратор. Адміністратори можуть переглядати всі документи учасників.',
  'To start working with documents, you need to add an organization': 'Щоб почати роботу з документами, вам потрібно додати організацію',
  'It looks like your organization does not have any users with member roles': 'Схоже, у вашій організації немає користувачів із ролями учасників',
  'Add document': 'Додати документ',
  Login: 'Вхід',
  Password: 'Пароль',
  'Forgot password?': 'Забули пароль?',
  'No account': 'Немає акаунта',
  'To register': 'Зареєструватися',
  Registration: 'Реєстрація',
  'Already have an account?': 'Вже є акаунт?',
  'Log in': 'Увійти',
  Phone: 'Телефон',
  'First name': 'ПІБ',
  'Confirm password': 'Підтвердження паролю',
  'Clear filter': 'Очистити фільтр',
  clear: 'очистити',
  Period: 'Період',
  choose: 'вибрати',
  Choose: 'Вибрати',
  Cleanup: 'Очистити',
  'To archive': 'В архів',
  Delete: 'Видалити',
  'Which meets filter values': 'Які відповідають критеріям фільтру',
  'Logout from account': 'Вийти з акаунта',
  'My organizations': 'Мої організації',
  'Add organization': 'Додати організацію',
  Name: 'Назва',
  EDRPOU: 'ЄДРПОУ',
  Participants: 'Участників',
  Role: 'Роль',
  'Go to organization': 'Перейти в організацію',
  'Add users': 'Додати користувачів',
  'In the fields, specify which users you want to add and assign them a role in the organization': 'У полях вкажіть яких користувачів ви хочете додати та задайте їм роль в організації',
  'Enter email': 'Введіть адресу електронної пошти',
  'Enter users role': 'Вкажіть роль користувачів',
  Owner: 'Власник',
  owner: 'Власник',
  'User management': 'Управління користувачами',
  'Invitations have been sent': 'Запрошення надіслано',
  'Change access': 'Зміна доступа',
  'You can change role of this user': 'Ви можете змінити роль данного користувача',
  'in the list of organizations or delete him there': 'для органзацій або видалити його',
  'Enter user roles': 'Вкажіть роль користувачів',
  'We can not find users...': 'Користувача з даною поштовою адресою не існує у системі',
  'As soon as users confirm the entry, you will receive a notification by mail': 'Як тільки користувачі підтвердять вступ, вам прийде повідомлення на пошту',
  'Manage members by adding or removing them from your organization. Assign moderators or administrators to manage organization members, change organization settings, and more.': 'Керуйте учасниками, додаючи або видаляючи їх з організації. Призначте модераторів або адміністраторів для управління членами організації, зміни налаштувань організації та багато чого іншого.',
  'Users role': 'Роль користувачів',
  'Change role': 'Змінити роль',
  Organization: 'Організація',
  Organizations: 'Організації',
  Users: 'Користувачі',
  'User role has been successfully changed': 'Роль користувача було успішно змінено',
  'User has been successfully deleted': 'Користувача було успішно видалено',
  'will be canceled and it will be removed from the user list': 'буде скасовано, і його буде видалено зі списку користувачів',
  Invite: 'Запрошення',
  'Come back': 'Повернутись назад',
  'Delete user': 'Видалити користувача',
  'User deletion': 'Видалення користувача',
  'Forgot your password?': 'Забули свій пароль?',
  'Forgot password': 'Забули пароль',
  'Enter your email address and we`ll send you a link to reset your password.': 'Введіть свою електронну адресу та перейдіть за посиланням для відновлення пароля.',
  'To login page': 'На сторінку входу',
  'Original document': 'Оригінальний документ',
  'Signed document': 'Підписаний документ',
  'Signed document (format p7s)': 'Підписаний документ (В форматі p7s)',
  'Document has been signed successfully': 'Документ успішно підписано!',
  'Documents has been signed successfully': 'Документи успішно підписано!',
  'Document date': 'Дата документа',
  'Document author': 'Завантажив/ла',
  'Uploaded by': 'Завантажив',
  Signs: 'Підписи',
  'Sign date': 'Дата підпису',
  Inbox: 'Вхідні',
  Outbox: 'Вихідні',
  'Outbox documents': 'Вихідні документи',
  'Items per page:': 'Кількість записів на сторінці:',
  Choosen: 'Обрано',
  'Documents list': 'Список документів',
  'EDS key': 'Ключ ЕЦП',
  'Mass documents sign': 'Масове підписання документів',
  Signing: 'Підписання',
  'Signing in the process': 'Підписується',
  'Stop sign': 'Перервати підписання',
  'Sign document with EDS key': 'Підпишіть документ за допомогою ЕЦП',
  'Skip sign': 'Пропустити підписання',
  Skipped: 'Пропущено',
  Scope: 'Всі документи',
  Internal: 'Внутрішні',
  External: 'Зовнішні',
  '{0}-{1} of {2}': '{0}-{1} з {2}',
  'You are successfully authorized!': 'Ви успішно авторизовані!',
  'You are successfully registered!': 'Ви успішно зареєстровані!',
  'For print': 'Для друку',
  'For print (with signature protocol)': 'Для друку (З протоколом підписання)',
  'Awaits signing': 'Очікує на підписання',
  'Enter the details of the organization/individual to whom you want to send the document': 'Вкажіть дані організації/фізичної особи, якій ви хочете надіслати документ',
  'Full name': 'ПІБ',
  'Email (login)': 'Email (логін)',
  'Enter full name': 'Введіть ПІБ',
  'Change full name': 'Змінити ПІБ',
  'Password are successfully updated': 'Пароль успішно змінено',
  'Full name are successfully updated': 'ПІБ успішно змінено',
  'You always can change full name in "Settings"': 'Ви завжди можете змінити ПІБ у "Налаштуваннях"',
  'You always can change password in "Settings"': 'Ви завжди можете змінити пароль у "Налаштуваннях"',
  'Change password': 'Зміна пароля',
  'Current password': 'Старий пароль',
  'New password': 'Новий пароль',
  'Incorrect current password.': 'Невірний поточний пароль.',
  'My account': 'Мій акаунт',
  'Inner document - you have access to all organizations of document': 'Внутрішній документ - ви маєте доступ до всіх організацій-сторін даного документу',
  Next: 'Далі',
  'Document loading...': 'Завантаження документа...',
  'Access Denied.': 'Відмовлено в доступі',
  'Setting of password': 'Встановлення пароля',
  'Resetting of password': 'Відновлення паролю',
  Restore: 'Відновити',
  Cancel: 'Скасувати',
  'Organization has been added successfully': 'Організацію успішно додано!',
  'To have access for the document, you should register at first': 'Для того, щоб переглянути документ, вам потрібно зареєструватися',
  'No items were found': 'Нічого не знайдено',
  'Add organization with EDS key': 'Додати організацію через ЕЦП ключ',
  'To add organization, select EDS key of organization and sign with it': 'Щоб додати організацію, виберіть файл ЕЦП ключа організації, яку ви хочете додати, та підпишіть його',
  Exit: 'Вихід',
  Filter: 'Фільтр',
  Filters: 'Фільтри',
  'Clear all': 'Очистити все',
  Expand: 'Розгорнути',
  Collapse: 'Згорнути',
  Apply: 'Застосувати',
  'There are no signs': 'Підписів немає',
  'Sign document and connect to us': 'Підпишіть документ і приєднайтеся до нашої спільної ініціативи',
  Actions: 'Дії',
  Save: 'Зберегти',
  'Document has been successfully signed': 'Документ успішно підписаний',
  'Please use the EDS key instead of seal': 'Будь ласка, використайте ключ ЕЦП замість печатки',
  'Login or registration': 'Вхід або реєстрація',
  'For sign in enter your email': 'Для входу введіть свою e-mail адресу',
  'Digitalized document management service from checkbox': 'Сервіс електронного документообігу від checkbox',
  'The easiest way to sign and share document': 'Найпростіший спосіб підписати та поділитися документом',
  'Enter e-mail': 'Введіть e-mail',
  'Approve who you are': 'Підтвердіть, що це Ви',
  'Approval code will come to': 'Код підтвердження прийде на електронну адресу',
  'Change e-mail': '← Змінити e-mail адресу',
  'Approval code': 'Код підтвердження',
  'Send code again': 'Вислати код ще раз',
  'Ask code again': 'Запросити код ще раз',
  Sender: 'Відправник',
  Recipient: 'Отримувач',
  Approve: 'Погодження',
  'Add code EDRPOU': 'Додати код ЄДРПОУ',
  'Extended settings': 'Розширені налаштування',
  'Add one more recipient': 'Додати ще отримувача',
  'What have to do': 'Що має зробити',
  'New document creation': 'Створення нового документа',
  'Send without signing': 'Надіслати без підпису',
  'Document has been successfully signed and sent': 'Документ успішно підписано та надіслано',
  'All participants of the agreement will receive a notification by mail about the need to process the document. As soon as they perform actions with the document, you will instantly receive a notification in the mail.': 'Всім учасникам підписання прийде повідомлення на пошту про необхідність опрацювати документ. Як тільки вони виконають дії з документом, ви миттєво отримаєте повідомлення на пошту.',
  'Getting to know the platform': 'Знайомство з платформою',
  Skip: 'Пропустити',
  'Send your first document': 'Відправити перший документ',
  'Create your organization': 'Створити свою організацію',
  'Invite colleagues for work': 'Запросити колег до роботи',
  'Invite colleagues': 'Запросити колег',
  'Our service is simple, you have done 1 out of 3 main steps, add organization': 'Ми настільки прості, що ви вже зробили 1 із 3 основних кроків, додайте організацію',
  'Our service is simple, you have done 2 out of 3 main steps, only invite colleagues left': 'Ми настільки прості, що ви вже зробили 2 із 3 основних кроків, залишилось запросити колег до роботи',
  'Specify the colleagues from whom you need to get approval': 'Вкажіть колег від яких потрібно отримати погодження',
  'Document has been successfully sent on approve': 'Документ успішно надіслано на погодження',
  'The sender will receive a message that you have sent the document for approval. In order for the document to be saved and available for you to view, we recommend creating an account, it will take 30 seconds': 'Відправнику прийде повідомлення про те, що ви відправили документ на погодження. Щоб документ зберігся та був доступний вам до перегляду - рекомендуємо створити акаунт, це займе 30 секунд',
  'The sender will receive a message that you have signed the document. In order for the document to be saved and available for you to view, we recommend creating an account, it will take 30 seconds': 'Відправнику прийде повідомлення про те, що ви підписали документ. Щоб документ зберігся та був доступний вам до перегляду - рекомендуємо створити акаунт, це займе 30 секунд',
  'Send on approve': 'Надіслати на погодження',
  'Send on sign': 'Надіслати на підпис',
  'All participants will receive a notification by mail about the need to process the document. As soon as they perform actions with the document, you will instantly receive a notification in the mail.': 'Всім учасникам прийде повідомлення на пошту про необхідність опрацювати документ. Як тільки вони виконають дії з документом, ви миттєво отримаєте повідомлення на пошту.',
  'Document has been successfully sent': 'Документ успішно надіслано',
  'All participants will receive a notification by mail about the need to process the document. As soon as they take action on the document, we will notify you instantly.': 'Всім учасникам прийде на пошту повідомлення про необхідність опрацювання документа. Як тільки вони виконають дії з документом, ми миттєво повідомимо вас.',
  'Add colleagues': 'Додати колег',
  'Create organization': 'Створити організацію',
  'Create account': 'Створити акаунт',
  'Easy start': 'Легкий Старт',
  'Add organization and sign documents instantly': 'Додайте організацію та починайте підписувати документи миттєво',
  'Your document has already been sent for approval and is now waiting for approval by all participants.': 'Ваш документ вже відправлено на погодження і тепер чекає на узгодження всіма учасниками.',
  'Sign document': 'Підписати документ',
  'Approval route': 'Маршрут погодження',
  'Reject document': 'Відхилити документ',
  'Approve document': 'Погодити документ',
  'Document has been successfully approved': 'Документ успішно погоджено',
  'The sender will receive a message that you have approved the document. In order for the document to be saved and available for you to view, we recommend creating an account, it will take 30 seconds': 'Відправнику прийде повідомлення про те, що ви погодили документ. Щоб документ зберігся та був доступний вам до перегляду - рекомендуємо створити акаунт, це займе 30 секунд',
  Signed: 'Підписано',
  Approved: 'Погоджено',
  Rejected: 'Відхилено',
  'Are you sure you want to reject the document?': 'Ви дійсно хочете відхилити документ?',
  'This comment will only be available to those who will receive this document': 'Цей коментар буде доступний тільки для тих, хто отримає цей документ',
  'Yes, reject': 'Так, відхилити',
  'Enter reason of document reject': 'Вкажіть причину відхилення документа',
  'Reason of document reject': 'Причина відхилення документу',
  'Document has been successfully rejected': 'Документ успішно відхилено',
  'The sender will receive a message that you have rejected the document and the reason for rejection. In order for the document to be saved and available for you to view, we recommend creating an account, it will take 30 seconds': 'Відправнику прийде повідомлення про те, що ви відхилили документ та причину відхилення. Щоб документ зберігся та був доступний вам до перегляду - рекомендуємо створити акаунт, це займе 30 секунд',
  'Document route': ' Маршрут документа',
  'Document details': 'Деталі документа',
  Type: 'Тип',
  'Document amount': 'Сума документа',
  View: 'Перегляд',
  'To view': 'Переглядати',
  Viewed: 'Переглянуто',
  'Have a look': 'Переглянути',
  'New document': 'Новий документ',
  Number: 'Номер',
  Status: 'Статус',
  'All statuses': 'Всі статуси',
  'Users have been sent a confirmation email': 'Користувачам надіслано листа на пошту для підтвердження',
  contractors: 'контрагенти',
  documentType: 'типи',
  'Rows per page': 'Кількість записів на сторінці',
  'Indicated everything correctly': 'Вказав все вірно',
  'We recommend first agreeing on the document and then signing it': 'Рекомендуємо спершу погодити документ, а потім підписувати',
  'To ensure that all parties agree to its content and terms. Agreement before signing allows you to ensure mutual understanding between all participants in the process and avoid misunderstandings after signing the document.': 'Щоб упевнитися, що всі сторони згодні з його вмістом та умовами. Погодження перед підписанням дозволяє забезпечити взаєморозуміння між всіма учасниками процесу та уникнути недорозумінь після підписання документа.',
  'Send document': 'Відправити документ',
  'Sign your first document': 'Підписати перший документ',
  'Your request to organization has been successfully accepted': 'Ви успішно приєдналися до організації',
  Auth: 'Авторизація',
  'Document has been deleted successfully': 'Документ успішно видалено',
  'We could not read the key data. Please try again or choose a different key': 'Ми не змогли зчитати дані ключа. Будь ласка, спробуйте ще раз або оберіть інший ключ',
  'The USREOU codes do not match. Please choose another': 'Коди ЄДРПОУ не співпадають. Оберіть інший ключ',
  signPlugin: {
    signDocument: 'Підпис документа',
    signDocuments: 'Підпис документів',
    sync: '<p>Щоб синхронізувати дані з ДПС, потрібно підписати запит ключем ЕЦП</p><p>Ви можете зробити це декількома способами: за допомогою файлового ключа ЕЦП або через SmartID</p>',
    syncDataWithSfs: 'Синхронізувати дані з ДПС',
    directorSign: 'Це вікно використовується для накладання підпису ФОП/директора ТОВ, власника акаунту Checkbox',
    smartId: '<p>Натисність або зчитайте QR-код сканером у застосунку Приват24 та дотримуйтесь інструкцій</p><p>Зверніть увагу, що сканування QR-коду відбуваєтсья декілька разів</p>',
    addOrg: 'Додати організацію',
    signWith: 'Підписати через'
  },
  Confirm: 'Підтвердити',
  RNOCPP: 'РНОКПП',
  Certificates: 'Сертифікати',
  'EDS (DSTU 4145)': 'ЕЦП (ДСТУ 4145)',
  'Key distribution protocols (DSTU 4145)': 'Протоколи розподілу ключів (ДСТУ 4145)',
  'The certificate issued': 'Сертифікат виданий',
  'Serial number': 'Серійний номер',
  'The certificate is valid': 'Сертифікат чинний',
  from: 'з',
  to: 'до',
  Profile: 'Профіль',
  'Document has been sent on approvals successfully': 'Документ успішно надісланий на погодження',
  'Possible formats: *.dat, *.pfx, *.pk8, *.zs2, *.jks': 'Формати key-6.dat, *.pfx, *.pk8, *.zs2, *.jks',
  'Document has been sent successfully': 'Документ успішно надіслано наступну учаснику маршруту',
  'The document will be signed by you and sent to all participants in the process': 'Документ буде підписаний вами та відправлений усім учасникам процесу',
  'I approve the document and hand it over to the owner for signature': 'Документ погоджую та передаю на підпис власнику',
  'The document will be rejected and sent to the owner for editing': 'Документ буде відхилено та передано власнику на редагування',
  'The document needs a preliminary one agreement before signing': 'Документ потребує попереднього узгодження перед підписанням',
  'Documents will be sent according to the specified route': 'Документи буде відправлено згідно заданому маршруту',
  Understand: 'Зрозуміло',
  'Date uploading': 'Дата завантаження',
  'Start work with Dubidoc': 'Початок роботи з Dubidoc',
  'Upload document': 'Завантажте документ',
  '(PDF, DOCX, JPG etc)': '(PDF, DOCX, JPG тощо)',
  'Sign it': 'Підпишіть його',
  '(via Diia or via other KEP)': '(за допомогою Дія або іншого КЕП)',
  'Share with others': 'Поділіться з іншими',
  '(link or via mail)': '(посилання або на пошту)',
  Welcome: 'Вітаємо',
  Edit: 'Редагувати',
  Share: 'Поділитися',
  'Go back': 'Назад',
  'Share this document': 'Поділіться цим документом',
  'You will send email with link to this document': 'Ви надішлете електронний лист із посиланням на цей документ',
  'Users with access': 'Користувачі, що мають доступ',
  You: 'Ви',
  'Public access': 'Загальний доступ',
  'Anybody with document link': 'Користувачі інтернету, які мають це посилання',
  'Add users email': 'Додайте e-mail користувачів',
  Ready: 'Готово',
  'Copy link': 'Копіювати посилання',
  'My data': 'Мої дані',
  'Welcome to Dubidoc': 'Ласкаво просимо в Dubidoc',
  'Upload your first document': 'Завантажте свій перший документ',
  '{document} has been successfully uploaded': '{document} успішно завантажено',
  'Add new organization': 'Додати нову організацію',
  'Document title has successfully changed': 'Назву документа успішно змінено',
  'Document has successfully archived': 'Документ успішно заархівовано',
  'Documents have successfully archived': 'Документи успішно заархівовано',
  'Archive document': 'Перенести в Архів',
  'Archive documents': 'Перенести в Архів',
  'This email is already taken': 'E-mail вже використовується',
  'This edrpou is already taken': 'ЄДРПОУ вже використовується',
  'No created organizations': 'Немає створених організацій',
  'Create your first organization to organize better your documents': 'Створіть свою першу організацію, щоб краще організувати та керувати вашими документами',
  'More details': 'Детальніше',
  'New user': 'Новий користувач',
  'New organization': 'Нова організація',
  'Copied to clipboard': 'Скопійовано в буфер обміну',
  'Participant has successfully deleted': 'Учасника документа успішно видалено',
  'New version available': 'Доступна нова версія',
  'This page could not be found': 'Вибачте, але сторінку не знайдено. Будь ласка, поверніться на головну сторінку.',
  'Come back to main page': 'Повернутися на головну',
  'Move to archive': 'Перенести в архів',
  'Move to documents': 'Перенести до документів',
  'Document has successfully unarchived': 'Документ успішно перенесено до всіх загального списку документів',
  'Documents have successfully unarchived': 'Документи успішно перенесено до всіх загального списку документів',
  Refresh: 'Оновити',
  'Error occurred. Try again later or contact with administrator': 'Сталася помилка. Спробуйте пізніше або зв\'яжіться з адміністратором',
  'Document has been signed': 'Документ підписано',
  'The sender will receive a message that you have signed the document. To start working with documents in the Dubidoc service, we recommend creating an account, it takes 30 seconds.': 'Відправнику прийде повідомлення про те, що ви підписали документ. Щоб почати роботу з документами в сервісі Dubidoc - рекомендуємо створити акаунт, це займе 30 секунд.',
  'Create an account': 'Створити акаунт',
  'Name has been changed successfully': 'ПІБ успішно змінено',
  'Enter your colleagues` email and assign them a role, and we`ll send a message telling them what to do next. You can always change access in the settings section': 'Вкажіть електрону пошту своїх колег та задайте їм роль, а ми надішлемо повідомлення і розповімо, що їм робити далі. Доступи ви завжди можете змінити у розділі “Користувачі”',
  Email: 'Електрона адреса',
  'Role in system': 'Роль в системі',
  'Send messages': 'Надіслати повідомлення',
  'Add one more user': 'Додати ще користувача',
  'Auth token is outdated. Renew Please': 'Час сесії минув. Будь ласка, виконайте вхід знову.',
  'Error occurred with {document} - {error}': '{document} не завантажено, виникла помилка: {error}',
  Ok: 'Добре',
  'All the documents you sent to the Archive are stored here (they are not deleted)': 'Тут зберігаються усі документи, які ви переслали в Архів (вони не видаляються)',
  'You (Owner)': 'Ви (Власник)',
  Copy: 'Копіювати',
  'Who can open document': 'Хто може відкривати документ',
  'User can': 'Користувач може',
  'Can sign': 'Може підписувати',
  'Can view': 'Може переглядати',
  'Everyone, who has link': 'Усі, хто мають посилання',
  'Private access': 'Обмежений доступ',
  'Only users with access to open this document': 'Лише користувачі з доступом можуть переходити за цим посиланням',
  Rename: 'Перейменувати',
  'Signatures and access': 'Підписи та доступи',
  'Document title must be minimum 3 characters': 'Назва документа повинна бути не менше 3-ох символів',
  'Add user email or EDRPOU and click +': 'Додайте E-mail або ЄДРПОУ отримувача та натисніть +',
  'Current function is at development stage': 'Дана функція знаходиться на етапі розробки',
  Tags: 'Мітки',
  'New tag': 'Нова мітка',
  'Help organize documents by providing a convenient way to quickly find, sort and use them': 'Допомагають організувати документи, забезпечуючи зручний спосіб їх швидкого пошуку, сортування та використання',
  'Enter name of new tag': 'Введіть назву нової мітки',
  'Choose tag color': 'Оберіть колір мітки',
  Create: 'Створити',
  'Tag edit': 'Редагування мітки',
  'Remove tag': 'Вилучити мітку',
  'Documents are labeled by this tag {tagName} will not deleted': 'Документи, які були відмічені цією міткою "{tagName}", не видаляються',
  'Tag {tagName} has added to document': 'Мітку "{tagName}" додано до документу',
  'Tag {tagName} has deleted from document': 'Мітку "{tagName}" було видалено із документу',
  'Add tag': 'Додати мітку',
  'Tag {tagName} has added to documents': 'Мітку "{tagName}" додано до документів',
  'All the documents you tagged to the Archive are stored here': 'Тут зберігаються усі документи помічені міткою "{tagName}"',
  'Tag name': 'Назва мітки',
  'Tag color': 'Колір мітки',
  'Access to document': 'Доступ до документу',
  'API key': 'API ключ',
  'Create API key': 'Створіть API ключ',
  'To integrate Dubidoc into other systems': 'Щоб інтегрувати сервіс Dubidoc з іншими системами',
  'Create new key': 'Створити новий ключ',
  'New key': 'Новий ключ',
  'Generated API key': 'Згенерований ключ API',
  'Title edit': 'Редагування назви',
  'Enter key name (not required)': 'Вкажіть назву ключа (не обовʼязково)',
  'Managing key, you set an integration': 'Цей ключ можна використовувати для налаштування інтеграції',
  'Generated API key copied': 'Згенерований ключ API скопійований',
  'Secret key': 'Секретний ключ',
  'API key copied': 'Ключ API скопійований',
  'API key created': 'API ключ створено',
  'Create key': 'Створити ключ',
  'Revoke key': 'Вилучити ключ',
  'Delete key': 'Видалити ключ',
  'This API key will be disabled immediately. APl requests made using this key will be rejected. Once revoked, you will no longer be able to view or change this API key': 'Цей ключ API буде автоматично вимкнено. Запити APl, зроблені за допомогою цього ключа буде відхилено. Після відкликання ви більше не можете переглядати чи змінювати цей ключ API',
  'Edit name': 'Редагувати назву',
  Display: 'Відобразити',
  Show: 'Показати',
  Hide: 'Сховати',
  'In documents list': 'У списку документів',
  'To many requests!': 'Забагато запитів!',
  'List of documents for signature': 'Перелік документів для підпису',
  'Documents signed': 'Документи підписано',
  'The process completed with some errors. Please check the indicated errors and try to sign these documents again': 'Процес завершено з деякими помилками. Перевірте вказані помилки та спробуйте підписати ці документи ще раз',
  'All selected documents were successfully signed': 'Всі обрані документи були успішно підписані',
  'Error occurred while signing': 'Виникла помилка при спробі підпису',
  'Signed before': 'Підписано раніше',
  'Document has signed already': 'Документ було підписано раніше',
  'Current organization': 'Поточна організація',
  'New users': 'Нові користувачі',
  'Send emails about documents': 'Надсилати повідомлення про документи',
  'Enter user email or EDRPOU': 'Введіть E-mail або ЄДРПОУ отримувача',
  Notifications: 'Повідомлення',
  Get: 'Отримувати',
  'Do not get': 'Не отримувати',
  'Documents to the email': 'Документи на пошту',
  'Change user role': 'Зміна ролі користувача',
  'You are going to change the role of the selected user in the system. The selected role will determine the level of access and user capabilities in the system': 'Ви збираєтеся змінити роль обраного користувача в системі. Вибрана роль визначатиме рівень доступу та можливості користувача в системі',
  'User role has been changed': 'Роль користувача змінено',
  'Documents notifications disabled': 'Повідомлення про документи “Вимкнено”',
  'Documents notifications enabled': 'Повідомлення про документи “Увімкнено”',
  'Enter value': 'Введіть значення',
  'E-stamp': 'Електронна печатка',
  Text: 'Текст',
  'Select date': 'Оберіть дату',
  Templates: 'Шаблони',
  'Documents search': 'Пошук документів',
  Search: 'Пошук',
  'Create document': 'Створити документ',
  'Save changes': 'Зберегти зміни',
  'Changes have successfully saved': 'Зміни успішно збережено',
  'Delete template': 'Видалити шаблон',
  'Are you sure to delete this template?': 'Ви дійсно бажаєте видалити даний шаблон?',
  Yes: 'Так',
  No: 'Ні',
  'Template has deleted successfully': 'Шаблон успішно видалено',
  'Save your time with templates': 'Економте час з шаблонами',
  'Document not found': 'Документ не знайдено',
  'Save the created templates for quick use with other recipients': 'Зберігайте створені шаблони для швидкого використання з іншими отримувачами',
  Checkbox: 'Галочка',
  Checkboxes: 'Галочки',
  'Please fill {count} required fields': 'Будь ласка, заповніть {count} обов\'язкових поля',
  Fill: 'Заповнити',
  Finish: 'Завершити',
  'Document has created successfully by template': 'Документ успішно створений на основі шаблону',
  'Changes have successfully saved into template': 'Зміни у шаблоні збережено',
  'Select variant from list': 'Оберіть варіант зі списку',
  'Dropdown list': 'Випадаючий список',
  'Add variant': 'Додати варіант',
  'E-signature': 'Електронний підпис',
  'Private documents': 'Особисті документи',
  'ALL ORGANIZATIONS': 'ВСІ ОРГАНІЗАЦІЇ',
  'Send document to E-mail': 'Надішліть документ на E-mail',
  'This organization is not yet registered in Dubidoc, we recommend sending the document to E-mail': 'Ця організація ще не зареєстрована в Dubidoc, рекомендуємо відправити документ на E-mail',
  'Diia.Sign': 'Дія.Підпис',
  'Open Privat': 'Відкрити Приват',
  'Sign with Privat': 'Підписати через Приват',
  Step: 'Крок',
  'Scan QR-code and follow instructions in Diia app': 'Відскануйте QR-код сканером у застосунку Дія та дотримуйтесь Інструкцій',
  'To sign the document, you need to log in to the Privat24 application': 'Для підписання документа треба авторизуватися в застосунку Приват24',
  'None of the participants have signed the document yet': 'Ще ніхто з учасників не підписав документу',
  'About us': 'Про нас',
  'Join us in Facebook': 'Приєднуйся до нас у Facebook',
  'Join us in Instagram': 'Слідкуйте за нами в Instagram',
  'Our news in Telegram': 'Наші новини в Telegram',
  'Watch us in Youtube': 'Дивіться нас на YouTube',
  'Join us in Linkedin': 'Приєднуйся до LinkedIn',
  'Our apps': 'Наші застосунки',
  'Download iOS app': 'Встановити застосунок iOS',
  'Download Android app': 'Встановити застосунок Android',
  'Share these documents': 'Поділіться цими документами',
  'Add participant': 'Додати отримувача',
  'Email or Edprou': 'E-mail або ЄДРПОУ',
  'Documents have successfully sent': 'Документи було успішно надіслано',
  'Delete organization': 'Видалення організації',
  'All documents connected with organization will be deleted': 'Всі документи, пов’язані з цією організацією, будуть безповоротно видалені',
  'The organization will be deleted at 11:59 p.m. the same day if you delete it before 6:00 p.m., or at 11:59 p.m. the next day if after 6:00 p.m.': 'Організація буде видалена о 23:59 того ж дня, якщо ви її видалите до 18:00, або о 23:59 наступного дня, якщо після 18:00',
  'Export documents before deletion': 'Експортувати документи перед видаленням',
  'Confirm delete': 'Підтвердити видалення',
  'Organization on a delete process': 'Організація на видаленні',
  'Your organization and all related documents are currently in the "For deletion" status. You can undelete to regain full access to the organization and its documents': 'Ваша організація та всі пов’язані з нею документи наразі знаходяться у статусі “На видаленні”. Ви можете скасувати видалення, щоб відновити повний доступ до організації та її документів',
  'On delete': 'На видаленні',
  cancel: 'Відмінити',
  'Organization delete canceled': 'Видалення організації скасовано',
  'Undo The organization and all related documents have been successfully restored. You have full access to the organization and its documents again': 'Організацію та всі пов’язані з нею документи успішно відновлено. Ви знову маєте доступ до організації та її документів у повному обсязі',
  'Cancel delete': 'Скасувати видалення',
  'This role does not allow to view organization`s documents': 'Ця роль не передбачає перегляд документів організації',
  'To fill': 'Заповнити',
  'Public template': 'Публічний шаблон',
  'Clients fill template by themself and you get prepared document': 'Клієнти самі заповнюють шаблон, а ви отримуєте готовий документ',
  'Scan quickly and conveniently': 'Скануй швидко та зручно',
  'Share link or QR-code': 'Діліться посиланням або QR–кодом',
  'Get filled documents without any time loses!': 'Отримуйте заповнені документи без зайвих витрат часу!',
  'Download QR-code': 'Завантажити QR-код',
  'Link copied': 'Посилання скопійоване',
  'Failed to create QR-code': 'Не вдалося створити QR-код',
  'Failed to load image': 'Не вдалося завантажити зображення',
  'Finish filling': 'Завершити заповнення',
  'Template has successfully filled': 'Шаблон успішно заповнено',
  'Add minimum 2 variants': 'Додайте мінімум 2 варіанти',
  'Work start': 'Початок роботи',
  'Upload file': 'Завантажте файл',
  'Add dynamic fields': 'Додайте динамічні поля',
  'which will be available for filling': 'які будуть доступні для заповнення',
  'Generate document': 'Створіть документ',
  'and share it via email, link or QR-code': 'Та ділитися через email, посилання або QR-код',
  'How it works': 'Як це працює',
  'Hi {name}': 'Привіт {name} 👋🏼',
  'Templates gives opportunity to create interactive documents, send them, fill and sign': 'Шаблони дають змогу створювати інтерактивні документи, надсилати їх іншим для заповнення та підписання',
  'This could be a contract, application, or any other document you want to automate': 'Це може бути договір, заявка або будь-який інший документ, створення якого ви хочете автоматизувати',
  'Setup dynamic fields': 'Налаштуйте динамічні поля',
  'Allow recipients to fill out the document exactly as needed': 'Це дозволить отримувачам заповнити документ згідно з вашими налаштуваннями',
  'Send template': 'Діліться документами',
  'Share a public template via link or QR code. The template is always saved in your account': 'Створюйте документи на основі шаблонів або робіть їх публічними через посилання чи QR-код, щоб отримувати заповнені й підписані документи',
  Try: 'Спробувати',
  'All fields are required to be valid': 'Всі поля повинні бути валідними',
  'To sign a document, you must first fill in all the dynamic fields. This can be done by either you or the recipient': 'Щоб підписати документ, потрібно спочатку заповнити всі динамічні поля. Це може зробити як ви, так і отримувач.',
  'Need to fill all fields': 'Необхідно заповнити усі поля',
  'Recommendation: after signing in Diia app come back to document page and wait for signature apply': 'Рекомендація: після підписання в застосунку "Дія" поверніться на сторінку документа та дочекайтесь відображення підпису',
  'Create template': 'Створіть шаблон',
  'Drag files here': 'Перетягніть файли сюди',
  'Supported formats:': 'Підтримуємо формати:',
  'Choose file': 'Виберіть файли',
  'Upload error': 'Помилка завантаження',
  'Unfortunately, we do not support this document type, or an unexpected error occurred': 'На жаль, ми не підтримуємо цей тип документа, або сталася неочікувана помилка',
  'Loading..': 'Завантаження..',
  filesCounter: {
    lessThanFour: 'файли',
    moreThanFour: 'файлів'
  },
  'Template uploader': 'Заватаження шаблону',
  Recommended: 'Рекомендовані',
  'Templates gallery': 'Галерея шаблонів',
  'Create or select ready template': 'Створіть або оберіть готовий шаблон',
  'New template': 'Новий шаблон',
  Select: 'Обрати',
  'Numeric field': 'Числове поле',
  Formula: 'Формула',
  Numeric: 'Число',
  Sum: 'Сума',
  'Select formula': 'Оберіть формулу',
  'Before adding "Formula" you should have at least one "Numeric field"': 'Щоб додати поле "Формула" потрібно хоча б одне "Числове поле"',
  'Formula should have at least one operand': 'Формула повинна містити хоча б одне "Числове поле"',
  'Choose a calculation formula': 'Оберіть формулу обчислення',
  'Refresh field': 'Очистити поле',
  'Add and sign': 'Додати та підписати',
  'Electronic ink signature': 'Електронно-чорнильний підпис',
  'Convert to PDF': 'Конвертувати в PDF',
  'Document has converted to PDF': 'Документ конвертовано в PDF',
  'By signing this document electronically, I agree that my signature and initials are the equivalent of my handwritten signature and are considered originals on all documents, including legally binding contracts.': 'Підписуючи цей документ в електронному вигляді, я погоджуюся з тим, що мій підпис та ініціали є еквівалентом мого рукописного підпису і вважаються оригіналами на всіх документах, включаючи юридично обов\'язкові контракти.',
  'Quick fill': 'Швидке заповнення',
  'Fill documents dynamic fields in simplified form': 'Заповніть доступні динамічні поля документа у спрощеній формі',
  'Documents not signed': 'Документи не підписано',
  'Average value': 'Середнє значення',
  'Delete document': 'Видалити документ',
  'Document will be deleted. Are you sure you want to delete it?': 'Документ буде безповоротно видалено. Ви впевнені що хочете видалити документ?',
  'API key has been successfully deleted': 'API ключ успішно видалено',
  'Tag has been successfully deleted': 'Тег успішно видалено',
  'You already downloaded the director\'s EDS signature as part of the session, so we took it from the cache. Wait for the operation to complete': 'Ви вже завантажували підпис ЕЦП директора в рамках сесії, тому ми взяли його з кешу. Очікуйте завершення операції',
  'Type of private key carrier': 'Тип носія особистого ключа',
  'File carrier': 'Файловий носій',
  'flash disk, CD disk, SD card, etc': 'flash-диск, CD-диск, SD-карта тощо',
  'Protected carriers': 'Захищені носії',
  'electronic key Almaz-1K, Crystal-1, Hryada-301, ID card, etc': 'е.ключ Алмаз-1К, Кристал-1, Гряда-301, ID-карта тощо',
  'Type of signature service': 'Тип сервісу підпису',
  'EDS service provider': 'Надавач послуги ЕЦП',
  'Personal key carrier': 'Носій особистого ключа',
  'Key protection password': 'Пароль захисту ключа',
  'Read key': 'Зчитати ключ',
  'Signature of the request to the DPS': 'Підпис запиту до ДПС',
  'Read the key via SmartID': 'Зчитати ключ через SmartID',
  'Signature via SmartID': 'Підпис через SmartID',
  'Read the key through Diia.Signature': 'Зчитати ключ через Дія.Підпис',
  'Signature via Diia.Signature': 'Підпис через Дія.Підпис',
  'EDS file key': 'Файловий ключ ЕЦП',
  'Check the read key information': 'Перевірте інформацію про зчитаний ключ',
  'Determine automatically': 'Визначити автоматично',
  'File is too large to be processed. Please select another file': 'Розмір файлу занадто великий для обробки. Оберіть інший файл',
  'An error occurred while processing the file': 'Під час обробки файлу сталася помилка',
  'The web signature library is not supported by your browser or OS': 'Бібліотека web-підпису не підтримується в вашому браузері або ОС',
  link: 'посиланням',
  'The web signature library requires the web extension to be installed. Please install the web extension by': 'Бібліотека web-підпису потребує встановлення web-розширення. Будь ласка, встановіть web-розширення за',
  'The web signature library needs updating. Please install the update by': 'Бібліотека web-підпису потребує оновлення. Будь ласка, встановіть оновлення за посиланням',
  'and refresh the page': 'та оновіть сторінку',
  'The web signature library needs to be installed. Please install the library by': 'Бібліотека web-підпису потребує встановлення. Будь ласка, встановіть бібліотеку за',
  'The field "Choose EDS key" must have a value': 'Поле "Оберіть ключ ЕЦП" повинно мати значення',
  Mean: 'Середнє значення',
  'Sorry, you have made too many requests in a short time. Please try again in a few minutes': 'Вибачте, але ви зробили занадто багато запитів за короткий час. Будь ласка, спробуйте ще раз через декілька хвилин.',
  'All documents have been successfully sent except for the instructions below.': 'Усі документи крім наведених нище успішно надіслано',
  'Fill and sign': 'Заповнити та підписати',
  'Document successfully rejected': 'Документ успішно відхилено',
  'Please provide a reason for rejecting the document. This comment will only be visible to the owner':
    'Вкажіть причину відхилення документа. Цей коментар буде доступний тільки для власника'
}
