import { render, staticRenderFns } from "./block-profile-menu.vue?vue&type=template&id=7a6d7d46&scoped=true&lang=pug"
import script from "./block-profile-menu.vue?vue&type=script&lang=js"
export * from "./block-profile-menu.vue?vue&type=script&lang=js"
import style0 from "./block-profile-menu.vue?vue&type=style&index=0&id=7a6d7d46&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a6d7d46",
  null
  
)

export default component.exports