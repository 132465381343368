import { get } from 'lodash/object'
import { find } from 'lodash/collection'
import auth from '~/modules/auth/auth'
import authUser from '~/modules/auth/authUser'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'
import CurrentOrganization from '~/modules/auth/Models/CurrentOrganization'

export default async function ({ route, from, store }, force = false, silent = true) {
  const excludedRoutes = ['index', 'auth-login', 'open-id', 'show-id', 'bulk-signature-id', 'widget-id', 'templates-id']
  if (route && excludedRoutes.includes(route.name)) {
    return
  }
  if (auth.getGuestAuthToken() && route.name === 'documents-id') {
    return
  }
  const user = authUser.get()
  if (!get(user, 'isSuperAdmin')) {
    try {
      const hasOrganization = store.state.organizations.items?.length
      const hasCurrentOrganization = CurrentOrganization.exists()
      if (!hasCurrentOrganization) {
        if (!hasOrganization) {
          await store.dispatch('organizations/getAll', { page: 1 })
        }
        const savedOrganizationId = currentOrganizationService.getDefaultOrganizationId(user)
        if (savedOrganizationId) {
          let savedOrganization = find(store.state.organizations.items, org => org.id === savedOrganizationId)
          if (!savedOrganization) {
            savedOrganization = await store.dispatch('organizations/read', savedOrganizationId)
          }
          currentOrganizationService.setCurrentOrganization(savedOrganization)
        }
      }
    } catch (e) {
      if (!silent) { throw e }
    }
  }
}
