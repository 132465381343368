import { get } from 'lodash/object'
import { uniqBy } from 'lodash/array'
import { find, map, filter } from 'lodash/collection'
import routes from '~/const/routes'

export const state = () => ({
  items: [],
  insertItems: [],
  meta: null
})

export const getters = {
  items: state => state.items,
  insertItems: state => state.insertItems,
  meta: state => state.meta
}

export const mutations = {
  CREATE_ITEMS (state, items) {
    state.items = items
  },
  INSERT_ITEMS (state, items) {
    state.insertItems = uniqBy([...state.insertItems, ...items], 'id')
  },
  ADD_ITEM (state, item) {
    const isUpdate = find(state.items, org => org.id === item.id)
    if (isUpdate) {
      state.items = map(state.items, org => org.id === item.id ? item : org)
    } else {
      state.items.push(item)
    }
  },
  REMOVE_ITEM (state, itemId) {
    state.items = filter(state.items, org => org.id !== itemId)
  },
  SET_ORGANIZATIONS_META (state, metaObj) {
    state.meta = metaObj
  },
  RESET (state) {
    state.items = []
    state.insertItems = []
    state.meta = null
  }
}

export const actions = {
  async getAll ({ commit }, { page, insert = false }) {
    const { data, meta } = get(await this.$axios.get(routes.organization.list(), {
      params: {
        offset: page,
        limit: 30
      }
    }), 'data')
    if (insert) {
      commit('INSERT_ITEMS', data)
    } else {
      commit('CREATE_ITEMS', data)
    }
    commit('SET_ORGANIZATIONS_META', meta)
    return { data, meta }
  },
  async read ({ commit }, id) {
    const organization = get(await this.$axios.get(routes.organization.concrete(id)), 'data')
    commit('ADD_ITEM', organization)
    return organization
  },
  async create ({ commit }, payload) {
    const organization = get(await this.$axios.post(routes.organization.list(), payload), 'data')
    commit('ADD_ITEM', organization)
    return organization
  },
  async delete ({ commit }, id) {
    await this.$axios.delete(routes.organization.concrete(id))
  },
  async restoreOrganization ({ commit }, id) {
    await this.$axios.post(routes.organization.restore(id), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}

export const namespaced = true
