<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpened"
    v-on="$listeners"
    :max-width="width"
    :showCloseBtn="!hideCloseButton"
    @input="close"
  )
    template(
      #title
      v-if="title"
    )
      p(
        class="ma-0 pa-0"
        :class="titleClass"
      ) {{ title }}
    template(
      #subtitle
      v-if="subtitle"
    )
      p(class="text-center ma-0 mt-2 pa-0") {{ subtitle }}
    template(#content)
      v-row(no-gutters)
        v-col(
          cols="12"
          v-if="text"
        )
          | {{ text }}
        v-col(
          cols="12"
          v-if="component"
        )
          component(
            :is="component"
            v-bind="{ ...componentProps, isOpened }"
            :closeModal="onCloseEvent"
            :changeDialogWidth="changeDialogWidth"
            v-on="componentListeners"
          )
    template(
      #actions
      v-if="showActions"
    )
      v-row(
        no-gutters
        justify="center"
      )
        v-col(
          cols="6"
          v-if="buttonText.approve"
        )
          v-btn(
            @click="confirm"
            class="main-button plain-error w-100"
          )
            | {{ buttonText.approve }}
        v-col(
          cols="6"
          v-if="buttonText.dismiss"
        )
          v-btn(
            @click="close"
            class="ml-4 main-button w-100"
          )
            | {{ buttonText.dismiss }}
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import Dialog from '~/models/system/Dialog'

// used components
// TODO this must be rethink
import MFormBlock from '~/components/modules/blocks/m-form-block'
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import BlockNotificationsList from '~/modules/notifications/views/components/block-notifications-list'
import BlockSingleNotification from '~/modules/notifications/views/components/block-single-notification'
import MSupportButtons from '~/components/modules/m-support-buttons'
import FormOrganizationsUsersAdd from '~/modules/organizations/views/components/form-organizations-users-add'
import FormOrganizationsUsersEdit from '~/modules/organizations/views/components/form-organizations-users-edit'
import BlockDocumentsDownload from '~/modules/documents/components/blocks/block-documents-download'
import FormChangeName from '~/modules/profile/views/components/form-change-name'
import BlockDocumentsSuccess from '~/modules/documents/components/blocks/block-documents-success'
import BlockOrganizationsSuccess from '~/modules/organizations/views/components/block-organizations-success'
import BlockDocumentsSignStatus from '~/modules/documents/components/blocks/block-documents-sign-status'
import FormDocumentShare from '~/modules/documents/components/forms/form-document-share'
import FormTag from '~/modules/tags/views/components/form-tag'
import BlockProfileApiKeysForm from '~/modules/profile/views/components/block-profile-api-keys-form'
import FormDocumentsMassShare from '~/modules/documents/components/blocks/form-documents-mass-share'
import BlockOrganizationsDelete from '~/modules/organizations/views/components/block-organizations-delete'
import BlockTemplateShare from '~/modules/templates/components/blocks/block-template-share'
import MTemplateUpload from '~/modules/templates/components/modules/m-template-upload'
import BlockDocumentsMassShareResults from '~/modules/documents/components/blocks/block-documents-mass-share-results'
import FormDocumentRejectConfirm from '~/modules/documents/components/forms/pro-version/form-document-reject-confirm'

export default {
  components: {
    TOrmButtons,
    TDialog,
    QrcodeVue,
    MFormBlock,
    MOrmTable,
    BlockNotificationsList,
    BlockSingleNotification,
    MSupportButtons,
    FormOrganizationsUsersAdd,
    FormOrganizationsUsersEdit,
    BlockDocumentsDownload,
    FormChangeName,
    BlockDocumentsSuccess,
    BlockOrganizationsSuccess,
    BlockDocumentsSignStatus,
    FormDocumentShare,
    FormTag,
    BlockProfileApiKeysForm,
    FormDocumentsMassShare,
    BlockOrganizationsDelete,
    BlockTemplateShare,
    MTemplateUpload,
    BlockDocumentsMassShareResults,
    FormDocumentRejectConfirm
  },
  props: {
    type: {
      type: String,
      default: 'content'
    }
  },
  data: () => ({
    dialogWidth: null
  }),
  computed: {
    dialog() {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened() {
      return this._.get(this.dialog, 'isOpened', false)
    },
    params() {
      return this._.get(this.dialog, 'params', '')
    },
    title() {
      const title = this._.get(this.params, 'title', '')
      return this._.isFunction(title) ? title(this) : this.$t(title)
    },
    subtitle() {
      const subtitle = this._.get(this.params, 'subtitle', '')
      return this._.isFunction(subtitle) ? subtitle(this) : this.$t(subtitle)
    },
    text() {
      const text = this._.get(this.params, 'text', '')
      return this._.isFunction(text) ? text(this) : this.$t(text)
    },
    hideCloseButton() {
      return this._.get(this.params, 'hideCloseButton', false)
    },
    showActions() {
      return this._.get(this.params, 'showActions', false)
    },
    component() {
      return this._.get(this.params, 'component')
    },
    componentProps() {
      return this._.get(this.params, 'componentProps')
    },
    componentListeners() {
      return this._.get(this.params, 'componentListeners')
    },
    buttonText() {
      return this._.get(this.params, 'buttonText')
    },
    width() {
      return this.dialogWidth || this._.get(this.params, 'width', '800px')
    },
    titleClass() {
      return this._.get(this.params, 'titleClass', 'text-center')
    },
    onClose() {
      return this._.get(this.params, 'onClose')
    }
  },
  created() {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    changeDialogWidth(val) {
      this.dialogWidth = val
    },
    async close() {
      this.changeDialogWidth(null)
      if (this._.isFunction(this.onClose)) {
        await this.onClose()
      }
      await this.dialog.close(false, true)
    },
    confirm() {
      this.dialog.close(true, true)
    },
    onCloseEvent(payload, confirmed = true) {
      this.dialog.close(payload, confirmed)
    }
  }
}
</script>
