<template lang="pug">
  div(
    v-if="hasOrganizations"
    class="organization-change"
  )
    div(
      class="organization-change__list"
      :class="{ 'scroll': organizations.length >= 5 }"
    )
      v-btn(
        v-for="organization in organizations"
        :key="organization.id"
        class="organization-change__item"
        height="48"
        @click="setOrganization(organization)"
      )
        div(class="d-flex align-center")
          div(class="organization-change__item-icon")
            v-img(:src="require('~/assets/images/bagcase.png')" max-width="16")
          span(class="organization-change__item-name") {{ organization.name }}
        v-icon(v-if="organization.id === currentOrganizationId" color="primary" small) mdi-check
      infinite-loading(v-if="hasMoreThanOnePage" @infinite="loadMore")
        template(v-slot:no-more)
          span
        template(v-slot:no-result)
          span
    div(class="organization-change__actions")
      v-btn(
        class="organization-change__add-btn"
        height="48"
        @click="$router.push('/my-organizations')"
      )
        div(class="organization-change__item-icon")
          e-svg-icon primary-circle-plus
        span(class="organization-change__item-name primary-text") {{ $t('Add new organization') }}
</template>

<script>
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import User from '~/models/administration/User'

export default {
  name: 'EOrganizationChange',
  components: {
    ESvgIcon,
    InfiniteLoading
  },
  data () {
    return {
      isLoading: false,
      loadingMore: false,
      current: null
    }
  },
  computed: {
    ...mapGetters('organizations', {
      items: 'insertItems',
      meta: 'meta'
    }),
    hasOrganizations () {
      return this.items && this.items.length
    },
    organizations () {
      const organizations = this.items
      const firstOrganization = this._.get(this.items, '[0]')
      const orgOwner = this._.get(firstOrganization, 'currentUserRole') === User.ROLES.owner
      if (organizations.length === 1 && !orgOwner) {
        return this._.concat(organizations, { id: null, name: this.$t('Private documents') })
      } else if (organizations.length > 1) {
        return this._.concat(organizations, { id: null, name: this.$t('ALL ORGANIZATIONS') })
      }
      return this.items
    },
    currentOrganization () {
      return currentOrganizationService.getCurrentOrganization()
    },
    currentOrganizationId () {
      return this._.get(this.current, 'id', null)
    },
    currentPage () {
      return this._.get(this.meta, 'currentPage', 1)
    },
    totalItems () {
      return this._.get(this.meta, 'totalItems', null)
    },
    itemsPerPage () {
      return this._.get(this.meta, 'itemsPerPage', 30)
    },
    lastPage () {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    hasMoreThanOnePage () {
      return this.totalItems > this.itemsPerPage && this.currentPage !== this.lastPage
    }
  },
  created () {
    if (this.currentOrganization) {
      this.current = this.currentOrganization
    }
    this.loadMore(null, true)
  },
  methods: {
    async setOrganization (organization) {
      this.isLoading = true
      try {
        this.current = organization
        if (organization && organization.id === this.currentOrganization?.id) {
          return
        }
        currentOrganizationService.setCurrentOrganization(organization)
        await User.api().setCurrentOrganization({ organizationId: organization.id })
        location.reload()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.isLoading = false
      }
    },
    async loadMore ($state, onInit = false) {
      try {
        if (this.currentPage >= this.lastPage && !onInit) {
          if ($state) {
            $state.complete()
          }
          return
        }
        this.loadingMore = true
        await this.$store.dispatch('organizations/getAll', { page: onInit ? 1 : this.currentPage + 1, insert: true })
        if ($state) {
          $state.loaded()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loadingMore = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.organization-change {
  border-radius: 12px;
  background: $background-secondary-alternative;

  &__list {
    display: flex;
    flex-direction: column;

    &.scroll {
      max-height: 240px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  &__item {
    background-color: transparent !important;
    padding: 0 !important;

    &::v-deep {
      .v-btn__content {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
      }
    }

    &:not(:first-child) {
      border-radius: 0 !important;
    }

    &:first-child {
      border-radius: 12px 12px 0 0 !important;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 32px;
      height: 32px;
      border-radius: 50%;

      background-color: rgba(18, 18, 18, 0.06);
    }

    &-name {
      max-width: 190px;
      overflow: hidden;
      font-size: 12px;
      line-height: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;
      padding-left: 8px;

      @media (min-width: map-get($breakpoints, 'lg')) {
        max-width: 200px;
      }
      @media (min-width: map-get($breakpoints, 'xl')) {
        max-width: 250px;
      }

      &.primary-text {
        color: $primary;
      }
    }
  }

  &__actions {
    border-top: 0.5px solid #FFF;
  }

  &__add-btn {
    width: 100%;
    background-color: transparent !important;
    border-radius: 0 0 12px 12px !important;
    padding: 0 !important;

    &::v-deep {
      .v-btn__content {
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        padding: 0 12px;
      }
    }
  }
}
</style>
