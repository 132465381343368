import Document from '~/modules/documents/models/Document'

export const state = () => ({
  params: null,
  documentScale: 1,
  shouldRerenderDocument: false
})

export const getters = {
  requestParams: state => state.params,
  documentScale: state => state.documentScale,
  shouldRerenderDocument: state => state.shouldRerenderDocument
}

export const mutations = {
  SET_DOCUMENTS_PARAMS (state, value) {
    state.params = value
  },
  SET_DOCUMENT_SCALE (state, scale) {
    state.documentScale = scale
  },
  SET_SHOULD_RERENDER_DOCUMENT (state, value) {
    state.shouldRerenderDocument = value
  }
}

export const actions = {
  async updateDocumentsWithSavedParams ({ state }) {
    return await Document.api().filter(state.params).all()
  }
}
