<template lang="pug">
  div(class="form-document-reject")
    div(class="form-document-reject__image")
      v-img(
        :src="require('~/assets/images/warning.png')"
        :max-width="80"
        class="mb-4"
      )
    h2(:class="[xsDevice ? 'title-24px' : 'title-32px']") {{ $t('Reject document') }}
    p(class="text-14px") {{ $t('Please provide a reason for rejecting the document. This comment will only be visible to the owner') }}
    v-form(@submit.prevent="submit")
      ValidationProvider(
        rules="required"
        :name="wrapQuotesProviderName($t('Enter reason of document reject'))"
        vid="reason"
        ref="reasonField"
        v-slot="{ errors }"
        mode="passive"
      )
        v-textarea(
          v-model="reason"
          filled
          :error-messages="errors"
          :label="$t('Enter reason of document reject')"
          height="100"
          no-resize
        )
      div(class="form-document-reject__actions")
        v-btn(
          height="48"
          @click="closeModal(null)"
        ) {{ $t('Come back') }}
        v-btn(
          class="secondary-button error-btn"
          type="submit"
          :loading="loading"
        ) {{ $t('Reject') }}
</template>

<script>
import validate from '~/mixins/validation/validate'
import Document from '~/modules/documents/models/Document'
import successSnackbar from '~/modules/snackbar/mixins/successSnackbar'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'FormDocumentRejectConfirm',
  mixins: [validate, successSnackbar, responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    document: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      reason: null,
      loading: false
    }
  },
  methods: {
    async submit() {
      const { valid } = await this.$refs.reasonField.validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true
        await Document.api().reject(this.document.id, { reason: this.reason })
        await Document.api().read(this.document.id)
        this.closeModal()
        await this.successSnackbar.open({
          text: this.$t('Document successfully rejected')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-document-reject {
  &__image {
    display: flex;
    justify-content: center;
  }

  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }
  }
}
</style>
