import abilityPlugin from './plugins/ability'
import { vuexORMPlugin, mapAbilities } from './plugins/vuex-orm'
import { ormConfig } from '~/const/global'
import routes from '~/const/routes'

export const plugins = [
  vuexORMPlugin(),
  abilityPlugin
]

export const state = () => ({})

export const mutations = {
  //
}

export const getters = {
  user (state) {
    return state.user
  },
  profile (state) {
    return state.user.profile
  },
  isAuthenticated (state) {
    return !!(state.user.token) && !!state.user.profile
  },
  ability (state) {
    return state.ability.update(mapAbilities(state.user.rules, ormConfig, process.env.mock.abilities))
  }
}

export const actions = {
  async synchronizeOrganization ({ commit }, payload) {
    return await this.$axios.put(routes.organization.synchronize(payload.id), payload.data)
  },
  resetStores ({ commit }) {
    commit('organizations/RESET')
  }
}
